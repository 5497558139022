<template>
  <div :style="personal ? 'height: calc(100vh - 62px)' : $vuetify.breakpoint.xs ? 'height: calc(100vh - 50px)' : 'height: 100vh'"
       class="grey lighten-5"
  >
    <div :style="$vuetify.breakpoint.mdAndUp ? 'height: 90px' : 'height: 74px'" v-if="!personal && $vuetify.breakpoint.smAndUp">
      <v-toolbar elevation="1" class="non-printable" style="position: relative;">
        <v-toolbar-title>Голосування та опитування</v-toolbar-title>
        <v-spacer/>
        <MainModal
            :main="{ component: 'Vote', title: 'Повідомлення' }"
            :button="{ icon: true, color: 'grey lighten-3 mr-2', buttonIcon: 'mdi-plus' }"
            v-if="!readOnly"
            :nonClickable="nonClickable"
        />
      </v-toolbar>
    </div>
    <div :style="personal ? 'height: calc(100vh - 62px);' :
          $vuetify.breakpoint.mdAndUp ? 'height: calc(100vh - 92px);' : 'height: calc(100vh - 78px);'"
         :class="$vuetify.breakpoint.mdAndUp ? 'ml-2' : ''">
      <v-row class="fill-height">
        <v-col cols="12" md="7" sm="5" class="pb-1"
               :style="getStyle()"
        >
          <v-row :class="$vuetify.breakpoint.smAndUp ? 'mt-1' : 'mx-1'">
            <v-col :class="$vuetify.breakpoint.smAndUp ? 'py-0' : 'pb-2 pt-4'">
              <v-chip-group :column="$vuetify.breakpoint.xs">
                <v-chip label close small
                        :color="filters.POLL ? '' : 'error lighten-1'"
                        :close-icon="filters.POLL ? 'mdi-minus-circle' : 'mdi-plus-circle'"
                        @click="filters.POLL = !filters.POLL"
                        @click:close="filters.POLL = !filters.POLL"
                >
                  <span :class="filters.POLL ? '' : 'text-decoration-line-through'">Опитування</span>
                </v-chip>
                <v-chip label close small
                        :color="filters.VOTING ? '' : 'error lighten-1'"
                        :close-icon="filters.VOTING ? 'mdi-minus-circle' : 'mdi-plus-circle'"
                        @click="filters.VOTING = !filters.VOTING"
                        @click:close="filters.VOTING = !filters.VOTING"
                >
                  <span :class="filters.VOTING ? '' : 'text-decoration-line-through'">Голосування</span>
                </v-chip>
                <v-chip label close small
                        :color="filters.INFORMING ? '' : 'error lighten-1'"
                        :close-icon="filters.INFORMING ? 'mdi-minus-circle' : 'mdi-plus-circle'"
                        @click="filters.INFORMING = !filters.INFORMING"
                        @click:close="filters.INFORMING = !filters.INFORMING"
                >
                  <span :class="filters.INFORMING ? '' : 'text-decoration-line-through'">Інформування</span>
                </v-chip>
                <v-chip label small
                        @click.stop="changeReadFilter('READ')"
                >
                  <span>Повідомлення: </span>
                  <span class="ml-1 font-weight-bold">
                    {{ filters.READ === null ? 'Усі' : filters.READ === true ? 'Прочитані' : 'Не прочитані' }}
                  </span>
                </v-chip>
                <v-chip label small
                        @click.stop="changeReadFilter('CLOSED')"
                >
                  <span>Статус: </span>
                  <span class="ml-1 font-weight-bold">
                    {{ filters.CLOSED === null ? 'Усі' : filters.CLOSED === true ? 'Закриті' : 'Діючі' }}
                  </span>
                </v-chip>
              </v-chip-group>
            </v-col>
          </v-row>
          <v-row class="flex-wrap" :class="$vuetify.breakpoint.smAndDown ? 'ma-0' : ''"
                 :style="!items.length ? 'height: 80%; align-items: center' : ''"
          >
            <template v-if="items.length">
              <v-col cols="12" md="6" sm="12" v-for="(item, index) in items" :key="index">
                <VoteItem :item="item" @selectVote="selectVote" :selected="selectedItem === item"></VoteItem>
              </v-col>
            </template>
            <template v-else>
              <div style="text-align: center;
                          font-weight: 500;
                          color: #2d2d2dcf;
                          width: 100%;
                          font-size: 1.7rem;
                          padding-top: 40px;
                          padding-right: 10px;
                          padding-left: 10px"
              >
                Дані для відображення відсутні. Спершу створіть голосування чи опитування
              </div>
            </template>

          </v-row>
        </v-col>
        <v-col cols="12" md="5" sm="7" class="pb-1"
               :style="getStyle2()"
               v-if="$vuetify.breakpoint.smAndUp"
        >
          <v-card class="fill-height px-3 pt-2 pb-0 grey lighten-3" tile id="vote-col">
            <VoteItemDescription :item="selectedItem"/>
          </v-card>
        </v-col>
        <v-col cols="12" md="5" sm="7" class="pb-1">
          <v-dialog v-model="dialog" fullscreen>
              <v-btn small text depressed absolute
                     block left right tile class="close grey lighten-4 pl-0 pr-0"
                     style="z-index: 999" height="28"
                     @click="dialog = false"
              >
                Закрити
              </v-btn>
            <v-card class="fill-height px-3 pa-3 grey lighten-3">
              <VoteItemDescription :item="selectedItem" dialog/>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import {VOTE_ITEM_CREATE, VOTE_ITEM_GET_ALL, VOTE_ITEM_REMOVE, VOTE_ITEM_UPDATE} from "@/store/actions/vote";
import {getVotingType} from "@/utils/icons"
import {ALERT_SHOW} from "@/store/actions/alert";

export default {
  name: "Voting",
  props: {
    personal: {
      type: Boolean,
      default: false
    },
    person_hash: {
      type: String,
      default: ''
    },
  },
  components: {
    MainModal: () => import("@/components/modal/MainModal"),
    VoteItem: () => import("@/components/VoteItem"),
    VoteItemDescription: () => import("@/components/VoteItemDescription")
  },
  computed: {
    ...mapGetters(
        {
          items: 'getVotes',
          socket_data: 'get_socket',
          profile: 'getProfile',
          organization: 'getCurrentOrganization'
        }
    )
  },
  data() {
    return {
      selectedItem: null,
      readOnly: false,
      nonClickable: false,
      height: 0,
      dialog: false,
      filters: {
        POLL: true,
        VOTING: true,
        INFORMING: true,
        READ: false,
        CLOSED: false,
        BUILDINGS: []
      }
    }
  },
  methods: {
    ...mapActions(
        { fetchItems: VOTE_ITEM_GET_ALL }
    ),
    changeReadFilter(col) {
      if (this.filters[col] === null) {
        this.filters[col] = true
      } else {
        if (this.filters[col] === true) {
          this.filters[col] = false
        } else {
          this.filters[col] = null
        }
      }
    },
    getStyle() {
      if (this.$vuetify.breakpoint.smAndDown) {
        return 'min-height: 200px'
      }
      if (this.$vuetify.breakpoint.mdAndUp) {
        return 'height: 100%'
      }
    },
    getStyle2() {
      if (this.$vuetify.breakpoint.smAndDown) {
        return 'height: 100%'
      }
      if (this.$vuetify.breakpoint.mdAndUp) {
        return 'height: 100%'
      }
    },
    selectVote(payload) {
      this.selectedItem = payload
      this.dialog = this.$vuetify.breakpoint.xs;
    },
    fetch_with_filter() {
      this.selectedItem = null
      const filters = JSON.parse(JSON.stringify(this.filters))
      if (!filters.BUILDINGS.length) {
        filters.BUILDINGS = null
      }


      this.fetchItems(filters)
    }
  },
  created() {
    this.fetch_with_filter()
  },
  watch: {
    socket_data: {
      immediate: true,
      handler(payload) {
        if (payload) {
          if (payload.data.ws_type === 'vote_crud'
              && payload.data.organization_id === this.organization.id
              && payload.owner !== this.profile.id
          ) {
            const obj = this.items.find(item => item.id === payload.data.id)
            if (obj) {
              this.$store.commit(VOTE_ITEM_UPDATE, payload.data)
            } else {
              const vote_type = getVotingType(payload.data.vote_type)
              this.$store.commit(VOTE_ITEM_CREATE, payload.data)
              this.$store.commit(ALERT_SHOW, { text: `Нове ${vote_type}: ${payload.data.theme}`, color: 'success' })
            }
          }

          if (payload.data.ws_type === 'vote_remove'
              && payload.data.organization_id === this.organization.id
              && payload.owner !== this.profile.id) {
            this.$store.commit(VOTE_ITEM_REMOVE, payload.data.id)
          }
        }
      }
    },
    person_hash: {
      immediate: true,
      handler(payload) {
        if (payload) {
          this.fetch_with_filter()
        }
      }
    },
    filters: {
      deep: true,
      handler() {
        this.fetchItems(this.filters)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.close {
  position: absolute;
  right: 1px;
  left: 1px;
  top: 1px;
  z-index: 999;
}
</style>